$(() => {
  $(".offcanvas-nav .language-menu a").click(window.changeLanguage);

  // Go through all the current nodes and open their parents
  $('.offcanvas-nav li.current').each(function () {
    $(this).parents('li').addClass('open');
  });

  // Toggle subcategories if category has children
  $(".offcanvas-nav ul li .toggle-icon").on("click", function (e) {
    e.preventDefault();
    $(this).parent().toggleClass("open");
  });
});
