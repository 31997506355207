function slowScrollToTop() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
}

function isOffcanvasNavOpen() {
    return document.body.classList.contains("offcanvas-open");
}

function setOffcanvasNavState(flag) {
    document.body.classList.toggle("offcanvas-open", !!flag);
}

function toggleClearAll() {
    var showClearAll = false;
    var $inputs = $(".filter-block :input");
    for(var i = 0; i < $inputs.length && !showClearAll; i++) {
        var $el = $($inputs[i]);
        var type = $el.prop("type");
        if(type === "checkbox" || type === "radio") {
            if($el.prop("checked")) {
                showClearAll = true;
            }
        } else if($el.val().length > 0){
            showClearAll = true;
        }
    }
    if(showClearAll) {
        $("#clear-all-filters").removeClass("hidden");
    } else {
        $("#clear-all-filters").addClass("hidden");
    }
}

function toggleMegaMenu() {
    var elem = $(this).find("div.mega-menu-container");
    const yAdjustment = 46;
    const posY = $(this).position().top - yAdjustment;
    elem.css("top", posY);
    elem.toggleClass("open");
    if (elem.hasClass("open")) {
        const identifier = ".mega-menu-container >ul";
        const elementWidth = elem.children(identifier).first().width();
        const maxUlPerRow = 4;
        const padding = 20;
        var ulCount = elem.children(identifier).length;
        if (ulCount > maxUlPerRow) {
            ulCount = max;
        }

        elem.css("width", elementWidth * ulCount + padding);
    }
}

$(function() {

    var is_mobile = false;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
         is_mobile = true;
    }

    $("#scroll_top").click(function(event) {
        event.preventDefault();
        slowScrollToTop();
    });

    $("#mega-menu .support-nav .dropdown-menu").click(function(event) {
        event.stopPropagation();
    });

    $("#mega-menu .category-link").hover(toggleMegaMenu);

    // Set up frontpage carousel
    $(".frontpage-carousel").carousel({
        interval: 6000,
        cycle: true,
        pause: false
    });

    // Set up owl carousel for product list with 5 items
    $(".owl-carousel.five").owlCarousel({
        margin: 20,
        nav: true,
        navText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ],
        responsiveClass: true,
        responsive: {
            0: { // breakpoint from 0 up
                items : 2,
                slideBy: 2
            },
            640: { // breakpoint from 640 up
                items : 4,
                slideBy: 2
            },
            1200: { // breakpoint from 992 up
                items : 5,
                slideBy: 3
            }
        }
    });

    //add tooltip triggers to data-attribute html with data-toggle=tooltip
    $("[data-toggle='tooltip']").tooltip({
        delay: { "show": 750, "hide": 100 }
    });

    $(window).scroll(function() {
        if ($(window).scrollTop() > 400) {
            $("#scroll_top").addClass("visible");
        } else {
            $("#scroll_top").removeClass("visible");
        }
    });

    // Adds a toggle icon to offcanvas navigation each element that have child elements
    $(".offcanvas-nav > ul li").each(function addOffcanvasToggler() {
        if ($(this).find("ul").length) {
            $(this).children("a").after("<span class='toggle-icon'></span>");
        }
    });

    /* Close offcanvas navigation if user clicks outside the nav element
    when the navigation is open */
    $(document).on("click touchstart", function(e) {
        if (isOffcanvasNavOpen() && !$(e.target).closest(".offcanvas-nav").length) {
            setOffcanvasNavState(false);
        }
    });

    // Toggle the visibility of offcanvas navigation when the menu icon is clicked
    $("#nav-toggler").click(function(e) {
        e.stopPropagation();
        setOffcanvasNavState(!isOffcanvasNavOpen());
    });

    // if user has mobile client, first click only opens the product card, second redirects to product page
    if (is_mobile) {
        $(document).on("click", ".product-card", function(e) {
            if ($(this).hasClass("touched")){
                return;
            } else {
                e.preventDefault();
                $(".touched").trigger("mouseleave").removeClass("touched");
                $(this).addClass("touched");
            }
        });
    }

    // product card hovering
    $(document).on("mouseenter", ".product-card", function() {
        var $actions = $(this).find(".actions").first();
        if ($actions.length && $actions.is(":empty")) {
            window.fetchProductActions($(this));
        } else {
            window.adjustProductCardOnMouseEnter($(this));
        }
    });

    $(document).on("mouseleave", ".product-card", function() {
        window.adjustProductCardOnMouseLeave($(this));
    });

    $(".menu-toggler").on("click", function() {
        var $li = $(this).parent("li");
        var action = "slideDown";
        if($li.hasClass("open")) {
            action = "slideUp";
        }
        $(this).parent("li").find("ul").first()[action]({
            complete: function() {
                $(this).parent("li").toggleClass("open");
            }
        });
    });

    $(".filter-block :input").on("change", function() {
        toggleClearAll();
    });
    toggleClearAll();
});

$(document).ready(function() {
    $(".selectpicker select").selectpicker();
    $(".category-link").on('mouseenter mouseleave', function (e) {
        if (!$(this).find(".mega-menu-container").length) {
            return;
        }
        $container = $(this).find(".mega-menu-container");

        var off = $container.offset();
        var l = off.left;
        var w = $container.width();
        var docW = $(".content-wrap").width();

        var isEntirelyVisible = (l + w <= docW);

        if (!isEntirelyVisible) {
            $(this).addClass('edge');
        } else {
            $(this).removeClass('edge');
        }
    });
    $("button.btn-remove").click(function(e) {
        e.preventDefault();
        $('<input />').attr('type', 'hidden')
                  .attr('name', $(this).attr("name"))
                  .attr('value', "")
                  .appendTo($("#basket_partial_remove"));
        $("#basket_partial_remove").submit();
    });
});
