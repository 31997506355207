window.adjustProductCardOnMouseEnter = function adjustProductCardMouseEnter(productCard) {
    var $actions = productCard.find(".actions").first();
    var $overlay = productCard.find(".render-on-hover").first();
    var bottom = $actions.outerHeight(true);
    productCard.find(".product-variations select").trigger("change");
    if(productCard.parent(".owl-item").length) {
        const actionHeight = $actions.outerHeight(true);
        const owlHeight = productCard.parent(".owl-item").first().outerHeight(true);
        const computedTop = (owlHeight - actionHeight) / 2;
        $actions.css("top", 0);
        $actions.css("padding-top", computedTop);
        $actions.css("padding-bottom", computedTop);
    }
    else {
        $overlay.css("bottom", -(bottom));
        $overlay.css("display", "block");
        $actions.css("display", "block");
    }
};

window.adjustProductCardOnMouseLeave = function adjustProductCardOnMouseLeave(productCard) {
    var $actions = productCard.find(".actions").first();
    var $overlay = productCard.find(".render-on-hover").first();
    var $detail = productCard.find(".detail-wrap").first();
    var bottom = $detail.outerHeight(true) - $actions.outerHeight(true);
    if(productCard.parent(".owl-item").length) {
        $actions.css("padding-top", 0);
        $actions.css("padding-bottom", 0);
        $actions.css("top", 0);
    }
    else {
        $overlay.css("bottom", 0);
        $actions.css("display", "none");
        $overlay.css("display", "none");
    }
};

window.fetchProductActions = function fetchProductActions(productCard) {
    var productId = productCard.attr("id").split("-")[1];
    jQuery.ajax({url: "/xtheme/product_actions", dataType: "html", data: {id: productId}}).done(function(responseText) {
        productCard.find("div.actions").html(responseText);
        if (productCard.is(":hover")) {
            adjustProductCardOnMouseEnter(productCard);
        }
        $("select.selectpicker").selectpicker();
    });
};
