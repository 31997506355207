window.updateVariationPrice = function updateVariationPrice(productId) {
  var $quantity = $('#product-quantity-' + productId);
  if ($quantity.length === 0 || !$quantity.is(':valid')) {
    return;
  }

  var data = {
    // In case productId is not available try to fallback to first input with correct name
    id: productId ? productId : $('input[name=product_id]').val(),
    quantity: $quantity.val(),
  };

  var $simpleVariationSelect = $('#product-variations-' + productId);
  if ($simpleVariationSelect.length > 0) {
    // Smells like a simple variation; use the selected child's ID instead.
    data.id = $simpleVariationSelect.val();
  } else {
    // See if we have variable variation select boxes; if we do, add those.
    $('#product-' + productId)
      .find('select.variable-variation')
      .serializeArray()
      .forEach(function(obj) {
        data[obj.name] = obj.value;
      });
  }

  jQuery
    .ajax({ url: '/xtheme/product_list_price', dataType: 'html', data: data })
    .done(function(responseText) {
      const priceDiv = '.product-price-div';
      var $content = jQuery('<div>')
        .append(jQuery.parseHTML(responseText))
        .find(priceDiv);
      jQuery('#product-' + productId + ' ' + priceDiv).replaceWith($content);
      if ($content.find("[id^='no-price']").length > 0) {
        $('.add-to-cart-button').prop('disabled', true);
      } else {
        $('.add-to-cart-button')
          .not('.not-orderable')
          .prop('disabled', false);
      }
    });
};
